<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import { orderBreadcrumbs } from '@/misc.js';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import MaxiModal from './components/MaxiModal.vue';
import Description from './components/Description.vue';
import { resolveImageUrl } from '@/imageResolver.js'
import {i18n} from "vue-lang-router";
import {orderCategories} from "../../misc";

export default {
  name: 'illustration',

  data() {
    return {
      imageInfo: null,
    };
  },

  created() {
    axios
      .get(`${process.env.VUE_APP_BACKEND_URL}/api/ext/image/${this.$route.params.extId}`)
      .then((res) => {
        this.imageInfo = res.data.data;
        document.title = this.imageInfo.headline + " | " + i18n.t("tempus");

      });
  },

  computed: {
    sortedTags() {
      return this.imageInfo.keywords.sort((a, b) => {
        const at = a.titleSv.toUpperCase();
        const bt = b.titleSv.toUpperCase();
        return at < bt ? -1 : at > bt ? 1 : 0;
      });
    },


    filteredCategories() {
      return orderCategories(orderBreadcrumbs(
        this.imageInfo.categories
          .filter(c => c.imagesCount > 0)
      ));
    },
  },

  methods: {
    ...mapActions('cart', {
      addToCart: 'add',
    }),

    displayModal() {
      this.$refs.modal.open();
    },

    openMaxi(imageInfo) {
      const url = resolveImageUrl(imageInfo.ext_id, 'maxi', imageInfo.html_title);
      window.open(url, '_self');
    },

    resolveImageUrl,
  },

  components: {
    Description,
    Breadcrumbs,
    MaxiModal,
  },
};
</script>

<template>
  <div class="illustration-info-container" v-if="imageInfo">
    <div class="illustration-info">
      <div class="content">
        <div class="w-full flex flex-col md:flex-row">
          <div class="flex-1 relative flex">
            <img style="cursor: pointer"    @click="openMaxi(imageInfo)" :alt="imageInfo.headline" class="m-auto w-auto h-64 object-contain" :src="resolveImageUrl(imageInfo.ext_id, 'medium', imageInfo.html_title)" />
<!--            <button-->
<!--              class="absolute bottom-0 right-0 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 rounded text-2xl"-->
<!--              @click="displayModal"-->
<!--            >-->
<!--              <i class="fas fa-expand-alt"></i>-->
<!--            </button>-->
          </div>
        </div>

        <maxi-modal ref="modal" :imageId="imageInfo.ext_id" />

        <div>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-4 px-4 rounded w-full md:w-56"
            @click="addToCart(imageInfo)"
            :disabled="!imageInfo.price"
          >
            {{$t('buy')}} ({{ imageInfo.price | amount }})
          </button>
        </div>

        <div class="header p-4">
          <p class="text-3xl">{{ imageInfo.headline }}</p>
        </div>

        <div class="text-sm">
          <span v-html="imageInfo.aboutMotifSv"></span>
        </div>

        <div class="description">
          <description v-model="imageInfo" />
        </div>

        <div class="tags">
          <h3>{{ $t("keyword") }}:</h3>
          <localized-link
            class="badge badge-secondary"
            v-for="(keyword, i) in sortedTags"
            :key="i"
            :to="{ name: 'keyword', params: { keyword: keyword.htmlTitle.toLowerCase() } }"


          >
            {{ keyword.titleSv }}
          </localized-link>
        </div>

        <div class="category">
          <h3>{{ $t("imageCategories") }}:</h3>

          <div v-for="(category, i) in filteredCategories" :key="i">
            <breadcrumbs v-model="filteredCategories[i]"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.illustration-info-container {
  text-align: left;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.05);

  .illustration-info {
    width: 75%;
    margin: 0 auto 0;
    background-color: #fff;

    @media screen and (max-width: $bp-mobile) {
      width: 100%;
      margin: 0;
    }

    .header {
      span {
        margin: 0 1em;
        cursor: pointer;
      }

      h3 {
        padding: 1em;
      }
    }

    .content {
      div {
        padding: 1em;
      }

      .about {
        p {
          margin: 0;
          font-size: 0.9em;
          line-height: 1.4em;
        }
      }

      .category {
        div {
          padding: 0;
          margin: 1em 0;

          &:last-of-type {
            margin-bottom: 0;
            border-bottom: none;
          }
        }
      }

      .description {
        background-color: #dfe6e9;
        padding-left: 0;
        padding-right: 0;
      }

      .tags {
        display: block;

        .badge {
          display: inline-block;
          font-size: 0.75em;
          padding: 0.5em 0.75em;
          border-radius: 5px;
          color: #fff;
          margin: 0 0.5em 0.5em 0;
          background-color: #1c1c68;
        }
      }
    }
  }
}
</style>
