<script>
import { resolveImageUrl } from '@/imageResolver.js'

export default {
  name: 'maxi-modal',

  props: {
    imageId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      display: false,
    };
  },

  methods: {
    open() {
      this.display = true;
    },

    close() {
      this.display = false;
    },

    resolveImageUrl,
  },
};
</script>

<template>
  <div class="bg-black bg-opacity-50 top-0 left-0 w-screen h-screen fixed text-center p-6" v-if="display">
    <div class="w-full h-full relative flex justify-center overflow-scroll">
      <button class="desktop-close close-btn h-12 w-12 rounded bg-blue-500 hover:bg-blue-700 text-white text-xl" @click="close">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
      <img class="maxi-image" :src="resolveImageUrl(imageId, 'maxi')">
    </div>
    <button class="mobile-close close-btn h-12 w-12 rounded bg-blue-500 hover:bg-blue-700 text-white text-xl" @click="close">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.mobile-close {
  top: 1.4rem;
  right: 1.4rem;
}

.maxi-image {
  max-width: unset !important;
}

@media (min-width:600px) {
  .mobile-close {
    display: none;
  }

  .desktop-close {
    display: block;
  }
}

@media (max-width:600px) {
  .flex {
    display: block !important;
  }
  .desktop-close {
    display: none;
  }

  .mobile-close {
    display: block;
  }
}
</style>